$body-bg: rgb(201, 63, 63);
$primary: $body-bg;

$body-color: white;

$card-bg: $body-bg;
$card-border-color: white;
$card-border-width: 2px;

$navbar-dark-color: rgb(201, 63, 63);
$navbar-light-color: rgba(201, 63, 63, 1);
